<script>
import shortConsumerInfo from '@/components/card-view/short-consumer-info.vue'
import FormView from '@/modules/components/form/index.vue'
import { mapGetters } from 'vuex'

import clueServices from '@/services/clueServices'
import orderServices from '@/services/orderServices'
import { uploadOrderProtocol,orderReview,orderReviewDetail,uploadOrderContract, uploadContractRemind } from '@/services/offlineOrder'
// import { clueOption } from './config'
import { dateFormat } from '@/utils/index.js'
import { configEnvData } from '@/constants/constants'
import { v4 as uuidv4 } from 'uuid'
import pdf from 'vue-pdf'
import loading from '@/utils/loading'
import OrderTitle from '../order/component/orderTitle'
import VehicleInfo from '../order/component/vehicleInfo'

export default {
  filters: {
    calculateFile(val) {
      const aa = val?.split('?q-sign-algorithm')[0]
      const bb = aa.substring(aa.lastIndexOf('_')+1)
      return bb ? decodeURI(bb) : val
    }
  },
  components:{
    shortConsumerInfo,
    FormView,
    pdf,
    OrderTitle,
    VehicleInfo
  },
  data() {
    return {
      // clueOption,
      leadId: '',
      id: '',
      orderId:'',
      clueInfo:{
        userName:'',
        userMobile:'',
        userSex:'',
        createTime:''
      },
      reviewInfo:{
        protocolUrl:'',
        orderSampleVo:{
          carOrderMaterial:{}
        },
        status: 0,
        type:0,
      },
      formData:{
        protocolUrl:'',
        contractImgUrl: '', // 合同为图片的url
        contractUrl:'', // 合同为文件的url
        infoUrl:''
      },
      depositAgreementList:[
        {
          label: '',
          field: 'protocolUrl',
          type: 'upload',
          fileList:[],
          maxlength:10,
        }
      ],
      // buyCarList:[
      //   {
      //     label: '',
      //     field: 'contractUrl',
      //     type: 'upload',
      //     fileList:[],
      //     maxlength:10,
      //   }
      // ],
      infoList:[
        {
          label: '',
          field: 'infoUrl',
          type: 'upload',
          fileList:[],
          maxlength:10,
        }
      ],
      lineType: 2,
      lineInfo:[
        {
          name:this.$t('线上签署'),
          code: 1,
        },
        {
          name:this.$t('线下签署'),
          code: 2,
        }
      ],
      refuseReason:'',
      show: false,
      protocolPdfUrl:'',
      contractPdfUrl:'',
      infoPdfUrl:'', // 个人信息
      currentPage: 0,
      pageCount: 0,
      currentPage2: 0,
      pageCount2: 0,
      currentPage3: 0,
      pageCount3: 0,
      // ----- loading -----
      remindShow: '加载文件中，文件较大请耐心等待...',
      // 加载进度
      loadedRatio: 0,
      loadedRatio2: 0,
      loadedRatio3: 0,
      enterPage: '',
      orderDetail: {},
      queryMark: false,
      contractFileType: 1, // 合同为图片1 合同为文件2
      fromPath: ''
    }
  },
  computed:{
    ...mapGetters([ 'userInfo' ]),
    // 审核
    isShowReviewBtn(){
      const { roleLogos=[] } = this.userInfo
      const { status, type } = this.reviewInfo
      // 定金协议 店长/Master审核
      // 合同协议 店长/Master审核 城市经理再审核
      const flag = [
        type === 1 && roleLogos.some((item) => { return ['1014003', '1014004'].includes(item) }) && status === 1,
        type === 2 && roleLogos.some((item) => { return ['1014003', '1014004'].includes(item) }) && status === 1,
        type === 2 && roleLogos.includes('1014030') && status === 3,
      ]
      return flag.some((item) => { return item })
    },
    // 能否上传定金协议
    getEditDepositAgreement() {
      const { type, status } = this.reviewInfo
      return (!!this.leadId) || (type === 1 && status === 10)
    },
    getTipsName() {
      const { orderStatus } =this.reviewInfo.orderSampleVo
      const obj = {
        'BE_LOCK':this.$t('请及时代客户锁单'),
        'DEPOSITE_BE_PAID':this.$t('请联系客户及时支付定金')
      }
      return orderStatus && obj[orderStatus] || ''
    },
    // 线下签署是否提交审核
    showOfflineConfirm() {
      const { orderSampleVo } = this.reviewInfo
      const { orderStatus } = orderSampleVo
      return (orderStatus==='ORDER_LOCKED' || orderStatus==='BE_CONTRACT') && this.lineType === 2 && this.showOffLine && this.userInfo.type === 1 && !this.showSubmitFromOrder
    },
    // 能否上传线下合同
    showOffLine() {
      // type=1定金2购车  status= 1待审核(店长) 3待审核(城市经理) 5 通过 10拒绝, 订单状态合同已签署-不可上传购车合同
      const { type, status, orderSampleVo } = this.reviewInfo
      const { orderStatus } = orderSampleVo
      return (orderStatus==='ORDER_LOCKED' || orderStatus==='BE_CONTRACT') && ((type === 2 && status === 10) || (type === 1 && status === 5)) || !!this.orderId
    },
    getTypeName() {
      const { type } = this.reviewInfo
      const obj={
        1:this.$t('定金协议'),
        2:this.$t('购车合同')
      }
      return type && obj[type] || ''
    },
    getStatusName() {
      const { type, status } = this.reviewInfo
      const obj = {
        1: type === 1 ? this.$t('待审核') : this.$t('店长/Master审核中'),
        3: this.$t('城市经理审核中'),
        5: this.$t('已审核'),
        10: this.$t('已驳回')
      }
      return status && obj[status] || ''
    },
    // 根据上传合同的类型 动态设置buyCarList
    buyCarList() {
      return [
        {
          label: '',
          field: this.contractFileType === 1 ? 'contractImgUrl' : 'contractUrl',
          type: 'upload',
          fileList:[],
          maxlength: this.contractFileType === 1 ? 20 : 1,
          format: this.contractFileType === 1 ? [] : ['.pdf']
        }
      ]
    },
    // 展示定金的提交审核
    showSubmit() {
      return this.leadId
    },
    // 直接从订单模块上传线下合同
    showSubmitFromOrder() {
      return !!this.orderId
    },
    // 展示关闭按钮
    showBack() {
      return !this.isShowReviewBtn && this.enterPage === 'order-detail' && !this.orderDetail.reviewVo
    },
    // 取消申请按钮(定金协议的驳回、通过 || 合同协议驳回)
    showCancelApply() {
      const { type, status } = this.reviewInfo || {}
      return this.userInfo.type === 1 && (type === 1 && [5,10].includes(status) || type === 2 && [10].includes(status)) && !this.showBack
    },
    // 定金协议驳回后
    showRepeat() {
      return this.userInfo.type === 1 && this.reviewInfo.type === 1 && this.reviewInfo.status === 10
    },
    // 定金协议通过后 或者 合同协议驳回后
    showMoreBtn() {
      return (this.reviewInfo.type === 1 && this.reviewInfo.status === 5) || ( this.reviewInfo.type === 2 && this.reviewInfo.status === 10)
    },
    // 一键催办
    showUrge() {
      const { type, status } = this.reviewInfo || {}
      // 待审核 且当前是购车合同
      return type === 2 && ([1,3].includes(status)) && !this.isShowReviewBtn
    },
    // 是否展示底部按钮
    showFooter() {
      return [this.isShowReviewBtn, this.showSubmit, this.showSubmitFromOrder, this.showRepeat, this.showCancelApply, this.showMoreBtn, this.showBack, this.showUrge].some((item) => { return item })
    },
  },
  watch: {
    contractFileType(val) {      
      const { contractImgUrl, contractUrl } = this.reviewInfo
      this.formData.contractUrl = ''
      this.formData.contractImgUrl = ''
      if (val === 1 && contractImgUrl) {
        const list2 = contractImgUrl.split(',')
        contractImgUrl && (this.buyCarList[0].fileList = list2.map((url)=>({ url,cosUrl:url,uuid:uuidv4() })))
        this.formData.contractImgUrl = contractImgUrl
      }
      if (val === 2 && contractUrl && !contractImgUrl) {
        const list2 = contractUrl.split(',')
        contractUrl && (this.buyCarList[0].fileList = list2.map((url)=>({ url,cosUrl:url,uuid:uuidv4() })))
        this.formData.contractUrl = contractUrl
      }
    }
  },
  async created() {
    const { id,leadId,orderId, enterPage, from='' } = this.$route.query
    this.enterPage = enterPage
    leadId && (this.leadId = leadId)
    id && (this.id = id)
    orderId && (this.orderId = orderId)
    this.fromPath = from
    if (this.orderId) {
      this.getDetail(this.orderId)
    }
    if (this.leadId){
      this.getClueDetail()
    } else if (this.id){
      this.orderReviewDetail()
    }
  },
  methods:{
    dateFormat,
    // 获取订单详情
    async getDetail(id) {
      loading.showLoading()
      const res = await orderServices.getOrderDetail({ id })
      loading.hideLoading()
      this.queryMark = true
      this.orderDetail = res
    },
    getClueDetail() {
      clueServices.getClueDetail({ id: this.leadId, }).then(res=>{
        this.clueInfo = res
      })
    },
    orderReviewDetail() {
      orderReviewDetail({ id: this.id }).then(res=>{
        Object.assign(this.reviewInfo,res)
        Object.assign(this.clueInfo,res?.leadSampleRes || {})
        const { protocolUrl = '', contractUrl='', protocolImgUrl = '', contractImgUrl='', infoUrl = '', infoImgUrl = '' } = res
        if (res.orderId) {
          this.getDetail(res.orderId)
        }
        if (contractImgUrl) {
          this.contractFileType = 1
        } else if (contractUrl) {
          this.contractFileType = 2
        }
        // 定金合同   type=1定金2购车  status= 5 通过 10拒绝
        if (this.getEditDepositAgreement) {
          // 能上传时=编辑((reviewInfo.type === 1 && reviewInfo.status === 10) || leadId)，要编辑原始的图片，所以获取 protocolImgUrl = '',contractImgUrl='';img图片
          this.formData.protocolUrl = protocolImgUrl
          const list = protocolImgUrl.split(',')
          protocolImgUrl && (this.depositAgreementList[0].fileList = list.map((url)=>({ url,cosUrl:url,uuid:uuidv4() })))
          // 个人信息
          this.formData.infoUrl = infoImgUrl
          const listInfo = infoImgUrl.split(',')
          infoImgUrl && (this.infoList[0].fileList = listInfo.map((url)=>({ url,cosUrl:url,uuid:uuidv4() })))
        } else {
          // 不能上传时=详情，需求是要把你的多张图片合成一个pdf.，所以获取protocolUrl = '',contractUrl=''；url是pdf
          if (protocolUrl) {
            this.formData.protocolUrl = protocolUrl
            const list = protocolUrl.split(',')
            this.depositAgreementList[0].fileList = list.map((url)=>({ url,cosUrl:url,uuid:uuidv4() }))
            // 有时PDF文件地址会出现跨域的情况,这里最好处理一下
            this.protocolPdfUrl = pdf.createLoadingTask({
              url: protocolUrl
            },{
              // 进度条
              onProgress:(status)=>{
                this.loadedRatio = status.loaded / status.total
              },
            })
          }
          // 个人信息
          if (infoUrl) {
            this.formData.infoUrl = infoUrl
            const listInfo = infoUrl.split(',')
            this.infoList[0].fileList = listInfo.map((url)=>({ url,cosUrl:url,uuid:uuidv4() }))
            // 有时PDF文件地址会出现跨域的情况,这里最好处理一下
            this.infoPdfUrl = pdf.createLoadingTask({
              url: infoUrl
            },{
              // 进度条
              onProgress:(status)=>{
                this.loadedRatio3 = status.loaded / status.total
              },
            })
          }
        }
        // 购车合同  type=1定金2购车  status= 5 通过 10拒绝
        if (this.showOffLine) {
          this.formData.contractUrl = contractUrl
          this.formData.contractImgUrl = contractImgUrl
          if (contractImgUrl) {
            const list2 = contractImgUrl.split(',')
            contractImgUrl && (this.buyCarList[0].fileList = list2.map((url)=>({ url,cosUrl:url,uuid:uuidv4() })))
          } else if (contractUrl) {
            // 合同为文件
            const list2 = contractUrl.split(',')
            contractUrl && (this.buyCarList[0].fileList = list2.map((url)=>({ url,cosUrl:url,uuid:uuidv4() })))
          }
        } else {
          this.formData.contractUrl = contractUrl
          this.formData.contractImgUrl = contractImgUrl
          // 有时PDF文件地址会出现跨域的情况,这里最好处理一下
          // 直接上传的文件 直接展示 由图片拼出来的文件渲染成pdf
          // if (contractImgUrl) {
          //   this.contractPdfUrl = pdf.createLoadingTask({
          //     url: contractUrl
          //   },{
          //     // 进度条
          //     onProgress:(status)=>{
          //       this.loadedRatio2 = status.loaded / status.total
          //     },
          //   })
          // } else if (contractUrl) {
          //   const list2 = contractUrl.split(',')
          //   contractUrl && (this.buyCarList[0].fileList = list2.map((url)=>({ url,cosUrl:url,uuid:uuidv4() })))
          // }
          if (contractImgUrl) {
            const list2 = contractImgUrl.split(',')
            contractImgUrl && (this.buyCarList[0].fileList = list2.map((url)=>({ url,cosUrl:url,uuid:uuidv4() })))
          } else if (contractUrl) {
            const list2 = contractUrl.split(',')
            contractUrl && (this.buyCarList[0].fileList = list2.map((url)=>({ url,cosUrl:url,uuid:uuidv4() })))
          }
        }
      })
    },
    // 改变PDF页码
    changePdfPage (val, type) {
      if (type === 'protocolPdfUrl') {
        if (val === 0 && this.currentPage > 1) {
          this.currentPage--
        }
        if (val === 1 && this.currentPage < this.pageCount) {
          this.currentPage++
        }
      } else if (type === 'contractPdfUrl') {
        if (val === 0 && this.currentPage2 > 1) {
          this.currentPage2--
        }
        if (val === 1 && this.currentPage2 < this.pageCount2) {
          this.currentPage2++
        }
      } else if (type === 'infoPdfUrl') {
        if (val === 0 && this.currentPage3 > 1) {
          this.currentPage3--
        }
        if (val === 1 && this.currentPage3 < this.pageCount3) {
          this.currentPage3++
        }
      }
    },
    // pdf加载时
    loadPdfHandler (e) {
      this.currentPage = 1 // 加载的时候先加载第一页
      this.currentPage2 = 1 // 加载的时候先加载第一页
      this.currentPage3 = 1 // 加载的时候先加载第一页
    },
    // 上传定金协议
    onSubmit(status=1) {
      if (!this.formData.protocolUrl && status === 1){
        return this.$toast(this.$t('请上传定金协议'))
      }
      if (!this.formData.infoUrl && status === 1){
        return this.$toast(this.$t('请上传个人信息处理告知同意书'))
      }
      this.$dialog.confirm({
        title: this.$t('提示'),
        message: status === 1 ? this.$t('确认提交审核？'):this.$t('取消申请后，所有申请数据将被删除，确认取消 ？'),
        confirmButtonColor: '#B9921A',
      }).then( () => {
        this.uploadOrderProtocol(status)
      })        
    },
    // 上传定金协议
    uploadOrderProtocol(status=1) {
      const { id:leadId,userName,userMobile, } = this.clueInfo
      const { id ='' } = this.reviewInfo
      const { protocolUrl, infoUrl } = this.formData
      loading.showLoading()
      uploadOrderProtocol({
        leadId,
        id,
        protocolUrl,
        infoUrl,
        dealerId: this.userInfo.dealers[0]?.id,
        customerMobile:userMobile,
        customerName:userName,
        status, // 1 待审核 15取消
      }).then(res=>{
        loading.hideLoading()
        this.$toast(this.$t('提交成功'))
        setTimeout(() => {
          this.onback()
        }, 1000)
      })
    },
    // 审核
    reviewConfirm(type='') {
      // 5 通过 10拒绝
      const status = type ? 5: 10
      if (status === 5){
        this.$dialog.confirm({
          title: this.$t('提示'),
          message: this.$t('确认审核通过？'),
          confirmButtonColor: '#B9921A',
        }).then( () => {
          this.orderReview({ status })
        })
        return
      }
      this.refuseReason = ''
      this.show = true
    },
    refuseReasonConfirm() {
      if (!this.refuseReason){
        return this.$toast(this.$t('请填写驳回原因'))
      }
      this.orderReview({ status:10,refuseReason: this.refuseReason })
    },
    orderReview(params) {
      orderReview({ ...params,id:this.reviewInfo.id }).then(res=>{
        this.$toast(this.$t('提交成功'))
        setTimeout(() => {
          this.onback()
        }, 1000)
      })
    },
    locationUrl(src) {
      // configuration车辆配置3005001  purchaseOrder订单3005002
      const configUrl = this.$store.getters.getDictHash(3005).find(item=>{
        return item.code === '3005001'
      })
      const orderUrl = this.$store.getters.getDictHash(3005).find(item=>{
        return item.code === '3005002'
      })
      const url = src === 'purchaseOrder' ? orderUrl?.descr : src === 'configuration' ? configUrl.descr : '' 
      window.location.href= url
    },
    changLine(tag) {
      if (this.showOffLine) {
        this.lineType = tag.code
      }
    },
    // 推送合同
    contractConfirm() {
      this.$dialog.confirm({
        title: '',
        message: this.$t('确认推送合同至客户签署 ？'),
        beforeClose: async (action, done) => {
          done()
          if (action === 'confirm') {
            loading.showLoading()
            await orderServices.orderContractConfirm(this.reviewInfo.orderId, 1)
            loading.hideLoading()
            this.$toast.success(this.$t('推送合同成功'))
            setTimeout(() => {
              this.onback()
            }, 500)
          }
        },
      })
    },
    // 线下签署合同
    uploadOrderConfirm(status = 1) {
      if (status === 1) {
        if ((!this.formData.contractUrl && this.contractFileType === 2) || (!this.formData.contractImgUrl && this.contractFileType === 1)){
          return this.$toast(this.$t('请上传文件'))
        }
      }
      this.$dialog.confirm({
        title: this.$t('提示'),
        message: status === 1 ? this.$t('确认提交审核？'):this.$t('取消申请后，所有申请数据将被删除，确认取消 ？'),
        confirmButtonColor: '#B9921A',
      }).then( () => {
        this.uploadOrderContract(status)
      }) 
    },
    // 一键催办
    async urgeProcessing() {
      await uploadContractRemind({ id: this.reviewInfo.id })
      this.$toast(this.$t('操作成功！'))
    },
    uploadOrderContract(status=1) {
      const { orderId,id='' } = this.reviewInfo
      loading.showLoading()
      uploadOrderContract({
        contractUrl: this.contractFileType === 2 ? this.formData.contractUrl : '',
        contractImgUrl: this.contractFileType === 1 ? this.formData.contractImgUrl : '',
        orderId:this.orderId || orderId,
        id,
        status, // 1 待审核 15取消
      }).then(res=>{
        loading.hideLoading()
        this.$toast(this.$t('提交成功'))
        setTimeout(() => {
          this.onback()
        }, 1000)
      })
    },
    // 跳转至详情
    goClueDetail({ id }) {
      this.$router.push({
        path: '/clue-details',
        query: {
          id,
        },
      })
    },
    goOrderDetail({ id }) {
      this.$router.push({
        path: '/order-detail',
        query: {
          id,
          offlineOrderId: this.id
        },
      })
    },
    // 取消关闭
    cancelBack() {
      this.$dialog.confirm({
        title: '',
        message: this.$t('确认取消？'),
        confirmButtonColor: '#B9921A',
      }).then( () => {
        this.onback()
      }) 
    },
    onback() {
      if (this.$route.query.source === 'news'){
        // wx.closeWindow()
        window.close()
      } else {
        this.$router.go(-1)
      }
    },
    // 跳转去订单
    goDetail() {
      this.$router.push({
        path: '/order-detail',
        query: {
          id: this.orderDetail.id,
        },
      })
    }
  }
}
</script>
<template>
  <div :class="['offlineOrderDetail', showFooter ? 'offlineOrderDetail_pd' : '' ]">
    <div class="detail">
      <div v-if="(isShowReviewBtn || reviewInfo.type === 2) && fromPath !== 'offlineOrder'" style="margin-bottom: 10px;">
        <p>{{ $t('线下合同审批节点：提交审核-店长/Master审核-城市经理审核') }}</p>
      </div>
      <div v-if="reviewInfo.status" style="color: #e4002c;" class="card">
        <span>申请状态：</span>
        <span v-if="reviewInfo.type == 1">{{getTypeName}}</span>
        <span>{{ getStatusName }}</span>
      </div>
      <template v-if="fromPath === 'offlineOrder' || reviewInfo.type === 1">
        <div class="card" @click.stop="goClueDetail(clueInfo)"> 
          <div class="title">{{$t('线下下单用户')}}</div>
          <div class="click-bg-color">
            <shortConsumerInfo :data="clueInfo"  :showLevel="false" :showIntention="false" :showSex="false" style="margin-bottom: 8px;"></shortConsumerInfo>
            <div class="info-item">
              <span class="label">{{$t('创建时间')}}：</span>
              <span class="create-time">{{dateFormat(clueInfo.createTime,'YYYY-MM-DD HH:mm')}}</span>
            </div>
          </div>
        </div>
        <div v-if="reviewInfo.orderSampleVo.id" class="card" @click.stop="goOrderDetail(reviewInfo.orderSampleVo)"> 
          <div class="title">
            <div>{{$t('用户订单')}}</div>
            <div>{{reviewInfo.orderSampleVo.orderStatus | codeFormat}}</div>
          </div>
          <div class="click-bg-color">
            <div class="info-item">
              <span class="label">{{$t('订单号')}}：</span>
              <span>{{reviewInfo.orderSampleVo.appOrderId}}</span>
            </div>
            <div class="info-item">
              <span class="label">{{$t('车系车型')}}：</span>
              <span>{{reviewInfo.orderSampleVo.carOrderMaterial.seriesName}}{{reviewInfo.orderSampleVo.carOrderMaterial.modelName}}</span>
            </div>
          </div>
        </div>
        <!-- 定金协议 -->
        <div class="card"> 
          <div class="title">
            <div>{{$t('定金协议')}}<span v-if="getEditDepositAgreement" style="color:red;">*</span></div>
          </div>
          <!--pdf预览-->
          <div v-if="protocolPdfUrl" class="read">
            <div class="pdf">
              <p class="arrow" style="text-align:center;margin: 10px;">
                <!-- // 上一页 -->
                <van-tag color="#9e9e9e" plain @click="changePdfPage(0, 'protocolPdfUrl')">上一页</van-tag>
                {{currentPage}} / {{pageCount}}
                <!-- // 下一页 -->
                <van-tag color="#9e9e9e" @click="changePdfPage(1, 'protocolPdfUrl')">下一页</van-tag>
              </p>
              <pdf
                  v-show="loadedRatio===1"
                  :src="protocolPdfUrl"
                  :page="currentPage"
                  @num-pages="pageCount=$event"
                  @page-loaded="currentPage=$event"
                  @loaded="loadPdfHandler">
              </pdf>
              <!-- 加载未完成时，展示进度条组件并计算进度 -->
              <div v-show="loadedRatio !==1" class="progress">
                <van-progress
                            :width="70"
                            color="#53a7ff"
                            :percentage="Math.floor(loadedRatio * 100)"></van-progress>
                <br>
                <!-- 加载提示语 -->
                <span>{{remindShow}}</span>
              </div>
            </div>
          </div>
          <FormView v-else ref="fromRef" :list="depositAgreementList" :isEdit="getEditDepositAgreement" :formData="formData"  inputAlign="left"/>
          <div v-if="!protocolPdfUrl" style="color:#949798;font-size: 14px;">{{$t('请拍摄并按顺序上传定金协议')}}</div>
        </div>
        <!-- 个人信息处理告知同意书 -->
        <div class="card">
          <div class="title">
            <div>{{$t('个人信息处理告知同意书')}}<span v-if="getEditDepositAgreement" style="color:red;">*</span></div>
          </div>
          <!--pdf预览-->
          <div v-if="infoPdfUrl" class="read">
            <div class="pdf">
              <p class="arrow" style="text-align:center;margin: 10px;">
                <!-- // 上一页 -->
                <van-tag color="#9e9e9e" plain @click="changePdfPage(0, 'infoPdfUrl')">上一页</van-tag>
                {{currentPage3}} / {{pageCount3}}
                <!-- // 下一页 -->
                <van-tag color="#9e9e9e" @click="changePdfPage(1, 'infoPdfUrl')">下一页</van-tag>
              </p>
              <pdf
                  v-show="loadedRatio3===1"
                  :src="infoPdfUrl"
                  :page="currentPage3"
                  @num-pages="pageCount3=$event"
                  @page-loaded="currentPage3=$event"
                  @loaded="loadPdfHandler">
              </pdf>
              <!-- 加载未完成时，展示进度条组件并计算进度 -->
              <div v-show="loadedRatio3 !==1" class="progress">
                <van-progress
                            :width="70"
                            color="#53a7ff"
                            :percentage="Math.floor(loadedRatio3 * 100)"></van-progress>
                <br>
                <!-- 加载提示语 -->
                <span>{{remindShow}}</span>
              </div>
            </div>
          </div>
          <FormView v-else ref="fromRef" :list="infoList" :isEdit="getEditDepositAgreement" :formData="formData"  inputAlign="left"/>
          <div v-if="!infoPdfUrl" style="color:#949798;font-size: 14px;">{{$t('请拍摄并按顺序上传个人信息处理告知同意书')}}</div>
        </div>
      </template>
      <!-- 订单详情进来 展示订单基础信息 -->
      <div v-if="(reviewInfo.type === 2 || enterPage === 'order-detail') && queryMark && fromPath !== 'offlineOrder'" class="card" @click="goDetail">
        <OrderTitle :orderDetail="orderDetail" />
        <VehicleInfo :showMore="true" :orderDetail="orderDetail" />
      </div>
      <!-- 购车合同模块 -->
      <template v-if="reviewInfo.orderSampleVo.orderStatus==='ORDER_LOCKED' || reviewInfo.orderSampleVo.orderStatus==='BE_CONTRACT' || orderId || reviewInfo.type === 2" >
        <div class="card">
          <div class="title">
            <div>{{$t('购车合同')}}<span v-if="showOffLine" style="color:red;">*</span></div>
          </div>
          <div class="contractTips">
            <van-icon name="warning-o" color="#e4002c" />
            <div v-if="showSubmitFromOrder || showOfflineConfirm">
              <p>{{ $t('上传前请确认系统展示的订单信息与上传的合同内呈现的内容一致') }}</p>
              <p>{{ $t('请务必确保图片从左到右显示顺序依次对应线下合同的第1页，第2页，第3页...保证后续读取图片生成的合同文件正确。') }}</p>
            </div>
            <div v-else>
              <p>{{ $t('审核时请重点检查系统实时展示的订单信息与上传的合同内呈现的订单信息一致') }}</p>
              <p>{{ $t('请确保按照下载时的电子版合同签署，并按照原页码及顺序，逐页上传签署完成的合同') }}</p>
            </div>
          </div>
          <div v-if="!orderId && enterPage !== 'order-detail'" class="lines">
            <div v-for="(tag,index) in lineInfo" :key="index" class="line" :class="{current:lineType === tag.code}" @click="changLine(tag)">{{ tag.name }}</div>
          </div>
          <template v-if="lineType === 2 || enterPage === 'order-detail'">
            <van-radio-group v-model="contractFileType" :disabled="!showOffLine" direction="horizontal">
              <van-radio :name="1">{{ '合同为图片' }}</van-radio>
              <van-radio :name="2">{{ '合同为文件' }}</van-radio>
            </van-radio-group>
            <!--pdf预览-->
            <div v-if="contractPdfUrl" class="read">
              <div class="pdf">
                <p class="arrow" style="text-align:center;margin: 10px;">
                  <van-tag color="#9e9e9e" plain @click="changePdfPage(0, 'contractPdfUrl')">上一页</van-tag>
                  {{currentPage2}} / {{pageCount2}}
                  <van-tag color="#9e9e9e" @click="changePdfPage(1, 'contractPdfUrl')">下一页</van-tag>
                </p>
                <pdf
                    v-show="loadedRatio2===1"
                    :src="contractPdfUrl"
                    :page="currentPage2"
                    @num-pages="pageCount2=$event"
                    @page-loaded="currentPage2=$event"
                    @loaded="loadPdfHandler">
                </pdf>
                <div v-show="loadedRatio2 !==1" class="progress">
                  <van-progress
                      :width="70"
                      color="#53a7ff"
                      :percentage="Math.floor(loadedRatio2 * 100)"></van-progress>
                  <br>
                  <span>{{remindShow}}</span>
                </div>
              </div>
            </div>
            <!-- <FormView v-if="!contractPdfUrl && enterPage !== 'order-detail'" :list="buyCarList" :isEdit="showOffLine" inputAlign="left" :formData="formData"/>
            <FormView v-if="!contractPdfUrl && enterPage === 'order-detail'" :list="buyCarList" :isEdit="true" inputAlign="left" :formData="formData"/> -->
            <FormView v-if="!contractPdfUrl" :list="buyCarList" :isEdit="showOffLine" inputAlign="left" :formData="formData"/>
            <p style="margin-top: 10px;">{{ $t('图片至多20张，单张不超过20M，文件至多1个，单个不超过30M') }}</p>
          </template>
          <div v-else-if="lineType === 1 && reviewInfo.orderSampleVo.orderStatus !== 'BE_CONTRACT'" class="send" @click.stop="contractConfirm"><van-icon name="guide-o" />{{$t('推送合同至客户签署')}}</div>
        </div>
      </template>
      <div v-if="reviewInfo.status === 10 && enterPage !== 'order-detail'" class="card">
        <div class="title">{{$t('驳回原因')}}</div>
        <div class="refuseReason">{{reviewInfo.refuseReason  }}</div>
      </div>
      <div v-if="getTipsName" class="tips">{{getTipsName}}</div>
      <div v-if="showFooter" class="common-footer">
        <!-- 直接从订单模块跳转过来 展示关闭 -->
        <van-button v-if="showBack" class="btn back" native-type="button" @click="cancelBack">{{$t('取消申请')}}</van-button>
        <!-- 定金协议的提交审核按钮 -->
        <van-button v-if="showSubmit" class="btn submit"  @click="onSubmit(1)">{{$t('提交审核')}}</van-button>
        <!-- 取消申请按钮 -->
        <van-button v-if="showCancelApply" class="btn back" native-type="button" @click="uploadOrderConfirm(15)">{{$t('取消申请')}}</van-button>
        <!-- 定金协重新提交审核的按钮 -->
        <template v-if="showRepeat">
          <van-button class="btn submit"   @click="onSubmit(1)">{{$t('重新提交审核')}}</van-button>
        </template>
        <!-- 直接从订单模块上传线下合同 -->
        <van-button v-if="showSubmitFromOrder" class="btn submit" @click="uploadOrderConfirm(1)">{{$t('提交审核')}}</van-button>
        <!-- 定金协议通过后 或者 合同协议驳回后 -->
        <!-- 一键催办 -->
        <van-button v-if="showUrge" class="btn submit" @click="urgeProcessing">{{$t('一键催办')}}</van-button>
        <template v-if="showMoreBtn">
          <!-- 有线下下单 -->
          <template v-if="reviewInfo.orderSampleVo.id">
            <!-- 已下定 未锁单 -->
            <van-button v-if="reviewInfo.orderSampleVo.orderStatus === 'BE_LOCK'" class="btn submit"  @click="locationUrl('purchaseOrder')">{{$t('去锁单')}}</van-button>
            <!--已下定 未支付-->
            <van-button v-else-if="reviewInfo.orderSampleVo.orderStatus==='DEPOSITE_BE_PAID'" class="btn submit"  @click="locationUrl('purchaseOrder')">{{$t('查看订单')}}</van-button>
            <!-- 已锁单 -->
            <van-button v-else-if="showOfflineConfirm" class="btn submit" @click="uploadOrderConfirm(1)">
              <span v-if="reviewInfo?.type === 2 && reviewInfo?.status === 10">{{$t('重新提交审核')}}</span>
              <span v-else>{{$t('提交审核')}}</span>
            </van-button>
          </template>
          <van-button v-else class="btn submit"  @click="locationUrl('configuration')">{{$t('线下下单')}}</van-button>
        </template>
        <!-- 店长/Master、城市经理审批按钮 -->
        <template v-if="isShowReviewBtn">
          <van-button class="btn back" native-type="button" @click.stop="reviewConfirm('')">{{$t('驳回')}}</van-button>
          <van-button  class="btn submit"  @click="reviewConfirm(1)">{{$t('同意')}}</van-button>
        </template>
      </div>
      <van-dialog v-model="show" :title="$t('提示')" show-cancel-button @confirm="refuseReasonConfirm">
        <van-field
          v-model="refuseReason"
          rows="1"
          autosize
          :label="$t('驳回原因')"
          type="textarea"
          maxlength='200'
          :placeholder="$t('请输入')"
        />
      </van-dialog>
    </div>
  </div>
</template>
<style lang="less" scoped>
.offlineOrderDetail{
  padding: 16px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  &.offlineOrderDetail_pd{
    padding-bottom: 96px;
  }
}
.detail{
  // padding:16px;
  font-size: 13px;
  .card{
    background: #fff;
    border-radius: 8px;
    padding:12px;
    margin-bottom: 16px;
    .title{
      border-bottom: 1px solid rgba(13, 23, 26, 0.05);
      padding-bottom: 12px;
      margin-bottom: 12px;
      display: flex;
      justify-content: space-between;
    }
    .click-bg-color {
      padding: 8px 12px;
      background: #F4F8FE;
    }
    .lines{
      display: flex;
      font-size: 12px;
      margin-bottom: 10px;
      .line{
        background: #F5F5F5;
        color:#949798;
        border-radius: 40px;
        text-align: center;
        margin-right: 8px;
        line-height: 21px;
        height: 20px;
        padding: 0 20px;
        cursor: pointer;
        &.current{
          background:#F7ECC8;
          color: #B9921A;
        }
      }
    }
    .info-item{
      margin-bottom: 8px;
      //.label{
      //  color: #929798;
      //}
      .create-time {
        font-size: 14px;
      }
    }
    .send{
      text-align: center;
      color: #B9921A;
      padding:8px 0;
      cursor: pointer;
    }
    .contractTips{
      color: #e4002c;
      display: flex;
      margin-bottom: 10px;
      .van-icon{
        margin: 3px 3px 0 0;
      }
    }
  }
  .tips{
    text-align: center;
    padding: 10px 0;
  }
  .refuseReason{
    font-size: 14px;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
}
// .common-footer{
//   height: auto;
//   .btn{
//     margin-bottom: 20px;
//     margin-top: 20px;
//   }
// }
</style>
